<template>
	<div>
		<div class="a-hero-container-small"></div>
		<div class="a-hero-container">
			<b-container class="a-hero-text-container d-flex mb-sm-5 mb-3">
				<div class="a-hero-text-container-inner w-100">
					<h1 class="mb-0 mt-0 mt-md-5">
						<span class="d-none d-md-inline-flex">{{ $t('Učitelia') }},</span>
						<span class="d-none d-md-inline-flex">{{ $t('pripravme') }}</span>
						<span class="d-inline-flex d-md-none">{{ $t('Učitelia') }}, {{ $t('pripravme') }}</span>
						<span class="w-color-primary z-fancy-underline">
							{{ $t('mladých na život!') }}
							<svg-fancy-underline-icon />
						</span>
					</h1>
					<p class="mb-0 mt-sm-5 mt-3">{{ $t('Bezplatné praktické video kurzy pre študentov základných') }}</p>
					<p>
						{{ $t('a stredných škôl.') }}
					</p>
					<b-button variant="primary" class="a-poppins-btn -wider mt-3" :to="buttonLink">{{ buttonText }}</b-button>
				</div>
			</b-container>
		</div>

		<a-top-courses v-if="courses" :courses="courses" category="teacher"/>
		<a-latest-courses v-if="courses" :courses="courses"/>

		<a-benefits :forTeachers="true"/>
		<a-how-it-works />
		<a-testimonials />
		<a-stats />
		<a-partners />
		<!-- <a-references /> -->
		<napisali-o-nas-about />
		<!-- <a-launch-competition-modal ref="launch-competition-modal"/> -->
	</div>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { mapGetters } from 'vuex'

const api = {
	courses:			() => wAxios.get_data('v1/courses'),
	authCourses:	() => wAxios.get_auth_data('v1/courses'),
	paths:				() => wAxios.get_data('v1/paths'),
	authPaths: 		() => wAxios.get_auth_data('v1/paths'),
}

export default {

	components: {
		'svg-fancy-underline-icon': 	() => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline'),
		'a-stats': 										() => import('./stats/a-stats'),
		'a-testimonials': 						() => import('./testimonials/a-testimonials'),
		// 'a-references': 							() => import('./references/a-references'),
		'a-benefits': 								() => import('./benefits/a-benefits'),
		'a-how-it-works': 						() => import('./how-it-works/a-how-it-works'),
		'a-partners': 								() => import('./a-partners'),
		'a-top-courses':							() => import('./courses/a-top-courses'),
		'a-latest-courses':						() => import('./courses/a-latest-courses'),
		// 'a-launch-competition-modal':	() => import('./modals/a-launch-competition-modal'),
		'napisali-o-nas-about':				() => import('@/plugins/appzmudri/about/components/NapisaliONas-about.vue'),
	},

	data() {
		return {
			courses: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		},
		isLoggedIn() {
			return this.$store.getters['wAuth/isLoggedIn']
		},
		buttonLink() {
			if (this.isLoggedIn) {
				if (this.isAdmin) {
					return '/triedy'
				}

				return '/kurzy'
			}

			return '/registracia'
		},
		buttonText() {
			if (this.isLoggedIn) {
				if (this.isAdmin) {
					return this.$t('Vytvoriť triedu')
				}

				return this.$t('Naše kurzy')
			}

			return this.$t('Poďte na to!')
		}
	},

	async mounted() {
		try {
			let courses = await (this.isLoggedIn ? api.authCourses() : api.courses())
			const paths = await (this.isLoggedIn ? api.authPaths() : api.paths())
			courses.forEach(course => {
				course._url = `/kurz/${course.slug || course.id}`
				course._model = 'course'
			})
			// NOTE: Comparison with integer, because '0' == true -> true
			courses = courses.filter(course => course.is_searchable == 1)
			paths.forEach(path => {
				path._url = `/seria/${path.slug || path.id}`
				path._model = 'path'
			})
			this.courses = courses.concat(paths)
		} catch (error) {
			this.courses = {error: 'Nepodarilo sa načítať kurzy'}
		}

		// this._showLaunchCompetitionModal()
	},

	methods: {
		// _showLaunchCompetitionModal() {
		// 	if (this.isLoggedIn) {
		// 		return
		// 	}

		// 	setTimeout(() => {
		// 		if (this.$route.path != '/pre-ucitelov' || localStorage.getItem('a-launch-competition-modal')) {
		// 			return
		// 		}
		// 		this.$refs['launch-competition-modal'].showModal()
		// 		localStorage.setItem('a-launch-competition-modal', 'true')
		// 	}, 5000)
		// }
	}
}
</script>
<style lang="sass" scoped>
.a-hero-container-small
	background-image: url('./_assets/background-teachers.png')
	background-position: -240px center

	@media only screen and (min-width: 360px)
		background-position: right top

	@media only screen and (min-width: 445px)
		height: 400px
		background-size: 750px

	@media only screen and (min-width: 600px)
		height: 450px
		background-size: 850px

.a-hero-container
	@media only screen and (min-width: 768px)
		background-image: url('./_assets/background-teachers.png')

	h1
		display: inline-flex
		flex-direction: column

		span
			margin-right: 10px

			&:last-child
				margin-right: 0

			@media only screen and (min-width: 768px)
				align-self: flex-start
				margin-right: 0

	button
		height: 60px
		width: 200px
</style>
